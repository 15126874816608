import * as React from 'react'
import { FaBook } from 'react-icons/fa'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import loadable from '@loadable/component'
import { useDisclosure } from './disclosure'
import { WhatsappButton } from './whatsapp-button'
import { CallButton } from './call-button'
import { Loader } from './loader'
import { LeftIcon } from './button-icon'
const NewService = loadable(() => import('./service'))

export interface MainCardProps {
  subTitle?: React.ReactNode
  timing?: React.ReactNode
}

export const MainCard: React.FC<MainCardProps> = ({
  subTitle,
  timing,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <div
      className={
        'relative bg-white rounded-md overflow-hidden pt-10 px-2 lg:px-6 xl:px-10 pb-2.5 md:pb-10'
      }
      style={{ minHeight: '347px' }}
    >
      {!isOpen && (
        <div style={{ color: '#4d5156' }}>
          <SliderHeading>
            {subTitle || 'Disponibles las 24 Horas Del Día'}
          </SliderHeading>
          <p>
            Tenemos cobertura en toda <strong>Bogotá</strong>.
          </p>
          <div className={'mt-5 flex flex-col space-y-3.5'}>
            <WhatsappButton />
            <CallButton action={'slider-box'}>Llamar al celular</CallButton>
            <button
              className={'btn btn-blue'}
              onClick={() => {
                trackCustomEvent({
                  category: 'button',
                  action: 'click',
                  label: `request-service`,
                })

                onOpen()
              }}
            >
              <LeftIcon>
                <FaBook />
              </LeftIcon>
              Cotizar
            </button>
            <p className={'text-sm'}>
              {timing || 'El tiempo de respuesta suele ser muy corto.'}
            </p>
          </div>
        </div>
      )}
      {isOpen && (
        <div style={{ color: '#4d5156' }}>
          <NewService fallback={<Loader />} onClose={() => onClose()} />
        </div>
      )}
    </div>
  )
}

export const SliderHeading: React.FC = props => {
  return (
    <h3
      className={'font-medium text-lg sm:text-xl lg:text-2xl'}
      style={{ color: '#071e55' }}
    >
      {props.children}
    </h3>
  )
}
