import * as React from 'react'

export const Loader: React.FC = () => {
  return (
    <div
      className={
        'absolute w-full h-full inset-x-0 top-0 flex justify-center items-center'
      }
    >
      Cargando...
    </div>
  )
}
