import * as React from 'react'
import { useImages } from './image'
import { MainCard } from './main-card'

type SliderName =
  | 'homeSlider'
  | 'lockSmithSlider'
  | 'electricianSlider'
  | 'plumberSlider'
  | 'fastDelivery'
  | 'unlockCar'
  | 'keysWithChip'

interface SliderProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'action' | 'src'> {
  business: string
  wallpaper: SliderName
  overlay?: number
  pageTitle: React.ReactNode
  pageDescription: React.ReactNode
  subTitle?: React.ReactNode
  goTo?: string
  action?: React.ReactNode
  timing?: string
}

export const Slider: React.FC<SliderProps> = ({
  business,
  wallpaper,
  pageTitle,
  pageDescription,
  goTo,
  action,
  subTitle,
  overlay,
  timing,
  ...props
}) => {
  return (
    <SliderBase overlay={overlay} alt={business} src={wallpaper} {...props}>
      <div
        className={
          'block md:flex text-center md:text-left flex-row items-center'
        }
      >
        <div style={{ flex: 3 }} className={'text-white'}>
          <h2
            className={
              'leading-5 mb-2 text-2xl sm:text-3xl md:text-5xl drop-shadow-sm'
            }
            children={pageTitle}
          />
          <p
            className={'md:text-xl leading-6 md:leading-9 drop-shadow-sm'}
            children={pageDescription}
          />
          {(action || goTo) && (
            <div className={'mt-3.5'}>
              {!action ? (
                <a href={goTo} className={'btn btn-blue hidden sm:inline-flex'}>
                  Ver más
                </a>
              ) : (
                action
              )}
            </div>
          )}
        </div>
        <div style={{ flex: 2 }} className={'ml-0 md:ml-5 mt-3.5 md:mt-0'}>
          <MainCard timing={timing} subTitle={subTitle} />
        </div>
      </div>
    </SliderBase>
  )
}

interface SliderBaseProps extends React.HTMLProps<HTMLDivElement> {
  src: SliderName
  alt: string
  overlay?: number
}

export const SliderBase: React.FC<SliderBaseProps> = ({
  alt,
  src,
  children,
  overlay = '0',
  ...props
}) => {
  const images = useImages()

  return (
    <div className={'relative py-12 md:py-40'} {...props}>
      <div
        // zIndex={1}
        className={'absolute inset-x-0 top-0 w-full h-full z-0 overflow-hidden'}
      >
        <picture className={'h-full hidden md:block'}>
          <source
            srcSet={images[src].childImageSharp.fluid.srcSetWebp}
            type="image/webp"
          />
          <source
            srcSet={images[src].childImageSharp.fluid.srcSet}
            type="image/jpeg"
          />
          <img
            alt={alt}
            sizes={images[src].childImageSharp.fluid.sizes}
            srcSet={images[src].childImageSharp.fluid.srcSet}
            src={images[src].childImageSharp.fluid.src}
            className={
              'slider-image-container object-cover object-center h-full w-full'
            }
          />
        </picture>
      </div>
      <div
        className={'absolute inset-x-0 top-0 w-full h-full z-0'}
        style={{ background: `rgba(0, 0, 0, ${overlay})` }}
      />
      <div
        // zIndex={2}
        className={'container relative z-5 px-4 2xl:px-48 text-white mx-auto'}
      >
        {children}
      </div>
    </div>
  )
}
