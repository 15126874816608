import * as React from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { FaWhatsapp } from 'react-icons/fa'
import config from '../components/config'
import { useUserAction } from './client-identity'
import { LeftIcon } from './button-icon'

export const WhatsappButton = () => {
  const [send] = useUserAction()
  const href = React.useMemo(() => {
    return `https://wa.me/${config.contact.extendedPhoneNumbers[0].replace(
      '+',
      '',
    )}?text=${encodeURI(config.social.whatsappMessage())}`
  }, [])

  return (
    <a
      className={'btn btn-lgreen'}
      target={'_blank'}
      rel={'noreferrer'}
      href={href}
      onClick={() => {
        trackCustomEvent({
          category: 'button',
          action: 'click',
          label: `whatsapp-message`,
        })
        send('Whatsapp')
      }}
    >
      <LeftIcon>
        <FaWhatsapp className={'text-xl'} />
      </LeftIcon>
      Mensaje de Whatsapp
    </a>
  )
}
